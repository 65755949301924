import React from 'react';

import Layout from '../components/layout';
import Bluebanner from '../components/blue-banner';
import '../components/about.css';
import Card from '../components/card';
import ContactBanner from '../components/contact-banner';
import Contact from '../components/contact';
import SEO from '../components/seo';

const About = () => (
  <Layout>
    <SEO title="About" />
    <Bluebanner
      source={require('../images/aboutt.svg')}
      title="About us"
      imgWidth={265.79}
      imgHeight="auto"
    ></Bluebanner>

    <section className="about">
      <div className="row">
        <p className="main-para">
          Firstbyte was born with a vision to provide top quality software
          services to its clients. The team is young, full of energy, and
          vibrant ideas. We make it a point to keep ourselves abreast with the
          latest information from the tech world. This enables us to use
          standard tools and conventions to build your dream product.
        </p>
      </div>
    </section>

    <section className="why-choose">
      <div className="row">
        <p className="dcp-title">Why choose us</p>

        <div className="row about-row">
          <div className="col span-1-of-3 box-about">
            <Card
              title="Quick results"
              des="Riding on the agile modal, we bring you prototypes at the earliest. This enables you to give early feedback helping us to fill any voids and iterare quickly."
              source={require('../images/result.svg')}
            ></Card>
          </div>

          <div className="col span-1-of-3 box-about">
            <Card
              title="Powerful apps"
              des="We build apps to drive your business forward. Automate your workflow, or build the products that your users will thank you for."
              source={require('../images/mob.svg')}
            ></Card>
          </div>

          <div className="col span-1-of-3 box-about">
            <Card
              title="Value for money"
              des="You have invested your time and money on us. We are committed to deliver you a rewarding experience."
              source={require('../images/money.svg')}
            ></Card>
          </div>
        </div>

        <div className="row about-row-small">
          <div className="col span-1-of-2 box-about">
            <Card
              title="Quick results"
              des="Riding on the agile modal, we bring you prototypes at the earliest. This enables you to give early feedback helping us to fill any voids and iterare quickly."
              source={require('../images/result.svg')}
            ></Card>
          </div>

          <div className="col span-1-of-2 box-about">
            <Card
              title="Powerful apps"
              des="We build apps to drive your business forward. Automate your workflow, or build the products that your users will thank you for."
              source={require('../images/mob.svg')}
            ></Card>
          </div>
        </div>

        <div className="row about-row-small">
          <div className="col span-1-of-2 box-about">
            <Card
              title="Value for money"
              des="You have invested your time and money on us. We are committed to deliver you a rewarding experience."
              source={require('../images/money.svg')}
            ></Card>
          </div>
          <div className="col span-1-of-2 box-about">
            <Card
              title="Efficient support"
              des="Bugs and issues are inevitable. What matters is how you react to them. We assure proactive support from our end."
              source={require('../images/support.svg')}
            ></Card>
          </div>
        </div>

        <div className="row about-row-small">
          <div className="col span-1-of-2 box-about">
            <Card
              title="Innovative technologies"
              des="We use only the best tools to build your products. Leveraging the vast open source ecosystem, you are in safe hands."
              source={require('../images/bulb.svg')}
            ></Card>
          </div>
          <div className="col span-1-of-2 box-about">
            <Card
              title="The human touch"
              des="We understand that we are building products for human beings. We empathise with your end users to craft user friendly products."
              source={require('../images/hand.svg')}
            ></Card>
          </div>
        </div>

        <div className="row about-row">
          <div className="col span-1-of-3 box-about">
            <Card
              title="Efficient support"
              des="Bugs and issues are inevitable. What matters is how you react to them. We assure proactive support from our end."
              source={require('../images/support.svg')}
            ></Card>
          </div>

          <div className="col span-1-of-3 box-about">
            <Card
              title="Innovative technologies"
              des="We use only the best tools to build your products. Leveraging the vast open source ecosystem, you are in safe hands."
              source={require('../images/bulb.svg')}
            ></Card>
          </div>

          <div className="col span-1-of-3 box-about">
            <Card
              title="The human touch"
              des="We understand that we are building products for human beings. We empathise with your end users to craft user friendly products."
              source={require('../images/hand.svg')}
            ></Card>
          </div>
        </div>
      </div>
    </section>
    <section className="contactt">
      <ContactBanner />
      <Contact />
    </section>
  </Layout>
);

export default About;
